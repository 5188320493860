import numeral from 'numeral'

export function aggregateCartItems(cartItems) {
    /* PRIMER STRUKTURE OBJEKTA */
    /*
    {
        name: '"Vintgar" prevoz nazaj na izhodišče',
        category: 'Odrasli',
        price: 6,
        amount: 3,
        type: 'prevoz'
    }*/

    let data = [];
    if(cartItems.length > 0) {
        cartItems.forEach(item => {
            if(!data.some(someItem => someItem.id === item.id)) {
                let newItem = item;
                newItem.amount = 0;
                data.push(newItem);
            }
        })

        if(data.length > 0) {
            data.forEach(dItem => {
                cartItems.forEach(cItem =>{
                    if(dItem.id === cItem.id) {
                        dItem["amount"] = dItem["amount"] + 1;
                    }
                })
            })
        }
    }

    //console.log(data);

    return data;
}

/** CONVERT HEX TO RGBA */
export function hexToRgba(hex, opacity) {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity +')';
    }
    throw new Error('Bad Hex');
}

export function formatPrice(price) {
    //numeral.locale('sl');
    //return numeral(price)
    //var number = 23.789;
    //console.log(new Intl.NumberFormat('sl-SI', { style: 'currency', currency: 'EUR' }).format(number))

    return new Intl.NumberFormat('sl-SI', { style: 'currency', currency: 'EUR' }).format(price)
}

