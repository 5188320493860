<template>
  <div class="view_container">

    <div id="content_main">
        <div id="homepage_image_container">
            <img id="homepage_image" :src="getHomepageImage"  alt="Vintgar">
        </div>

        <div id="homepage_text">
            <p>{{ languageData.opis_text_1 }}</p>
            <p>{{ languageData.opis_text_2 }}</p>
        </div>

        <div id="homepage_price_list">
          <!--<h2 id="price_list_title">{{ languageData.cenik }}</h2>-->

          <div id="price_list_content">
              <div id="price_table_1" class="price_table">
                  <!--<table>
                    <thead>
                      <tr>
                        <th class="th_first" style="text-align:left;border-top-left-radius:8px; border:2px solid #007CA4;">POSAMEZNIKI</th>
                        <th class="th_second" style="text-align:right;border-top-right-radius:8px; border:2px solid #007CA4;">enosmerna</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="">Odrasli</td>
                        <td style="text-align:right;">10€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Študenti / Upokojenci</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">7€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754">Otroci 6 - 15 let</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">2€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Otroci do 6 let</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">1€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Ljubljenčki</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">3€</td>
                      </tr>
                    </tbody>
                  </table>-->
                  <table>
                    <tr>
                      <td><strong>GPS</strong> (ss° mm.mm): N46° 10.86, E13° 46.76.</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.primeren_veter.title }}: </strong>{{ languageData.primeren_veter.value }}</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.visina_amsl.title }}: </strong>{{ languageData.visina_amsl.value }}.</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.zracni_prostor.title }}: </strong> {{ languageData.zracni_prostor.value }}.</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.zahtevnost_vzleta.title }}: </strong> {{ languageData.zahtevnost_vzleta.value }}.</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.zahtevnost_letenja.title }}: </strong> {{ languageData.zahtevnost_letenja.value }}.</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.primerno_za_zacetnike.title }}: </strong> {{ languageData.primerno_za_zacetnike.value }}.</td>
                    </tr>
                    <tr>
                      <td><strong>{{ languageData.dostop.title }}: </strong> {{ languageData.dostop.value }}.</td>
                    </tr>
                  </table>
                  
              </div>
              <div id="price_table_2" class="price_table">
                <!--<table>
                    <thead>
                      <tr>
                        <th class="th_first" style="text-align:left;">SKUPINE*</th>
                        <th class="th_second" style="text-align:right;">enosmerna</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tbody>
                      <tr>
                        <td style="text-align:left; border-top: 1px solid #77777754;">Odrasli</td>
                        <td style="text-align:right; border-top: 1px solid #77777754; text-align:right;">8€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Študenti / Upokojenci</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">5€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Otroci 6 - 15 let</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">2€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Otroci do 6 let</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">1€</td>
                      </tr>
                      <tr>
                        <td style="border-top: 1px solid #77777754;">Ljubljenčki</td>
                        <td style="text-align:right; border-top: 1px solid #77777754;">3€</td>
                      </tr>
                    </tbody>
                    </tbody>
                  </table>-->
                  <div id="container_map_kobala">
                    <img id="map_kobala" :src="getMapKobalaThumbImage" />
                  </div>
              </div>
          </div>

        </div>
    </div>

    <div id="content_footer">
      <div class="footer_btn_item">
        <transition name="fade">
          <button class="btn_footer btn_footer_green" @click="goToStore()">{{ languageData.nakup }} <i class="fas fa-chevron-right"></i></button>
        </transition>
      </div>
      <div class="footer_btn_item">
        <button class="btn_footer btn_footer_blue" @click="goToInformations()">{{ languageData.informacije }} <i class="fas fa-chevron-right"></i></button>
      </div>
      <div class="footer_btn_item">
        <button class="btn_footer btn_footer_blue" @click="goToHelp()">{{ languageData.pomoc }} <i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>
</template>

<script>

import vintgarImg from '@/assets/vintgar_13_2.jpg'
import homePageImg from '@/assets/home_page_img.jpg'
import mapKobala from '@/assets/map_kobala.png'
import mapKobalaThumb from '@/assets/map_kobala_thumb.jpg'

export default {

  data: () => ({
    text1: "Vzletna točka Kobala in vzletna točka Vrše s pristajalnim mestom Tolmin so namenjeni za letenje jadralnih zmajarjev in jadralnih padalcev, in sicer za športno letenje, tekmovanja, prireditve in za usposabljanje pilotov jadralnih zmajev in jadralnih padal.",
    text2: "Najboljši pogoji za dolge prelete so, kadar piha zelo šibak splošni S ali V veter. Običajno prevladuje zahodnik, ki je primernejši za lokalno jadranje. Zelo velik, lepo urejen travnik na kopastem vrhu sicer z gozdom porasle Kobale. Glede na veter sta na izbiro dve smeri vzleta (V in Z).",
    text3: "Cenik"
  }),

  watch: {

  },

  computed: {
    getVingtarImage() {
      return vintgarImg;
    },

    getHomepageImage() {
      return homePageImg
    },

    getMapKobalaImage() {
      return mapKobala
    },

    getMapKobalaThumbImage() {
      return mapKobalaThumb
    },

    languageData() {
      return this.$store.getters.languageData
    }
  },

  methods: {
    goToStore() {
      return this.$router.push({
          name: 'Store'
      })
    },

    goToInformations() {
      return this.$router.push({
        name: 'Informations'
      })
    },

    goToHelp() {
      return this.$router.push({
        name: 'Help'
      })
    },

    
    
  },

  created() {
  },

  mounted() {

  },

  unmounted() {

  }

}

</script>

<style scoped>


.footer_btn_item {
  width:30%;
}

.btn_footer {
  width:100%;
  height:92px;
  font-size:18px;
  line-height:21px;
  text-align:center;
  font-family: 'Roboto', sans-serif;
  font-style:normal;
  border:none;
}

.btn_footer:focus {
  border:none;
} 

#homepage_image_container {
  height:313px;
  width:100%;
}

#homepage_image {
  width:100%;
  height:100%;
  object-fit: cover;
}

#homepage_text {
  width:100%;
  /*border:1px solid purple;*/
  height:117px;
}

#homepage_text p {
  color: #777777;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  padding-right:20px;
  padding-left:20px;
  text-align:center;
}

#price_list_title {
   color:#007CA4;
   font-family: 'Roboto', sans-serif;
   font-style: normal;
   width:100%;
   text-align:center;
   text-transform: uppercase;
}

#price_list_content {
  margin:5px;
  min-height:50px;
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  /*border:1px solid brown;*/
}

.price_table {
  width:50%;
}

#price_table_1 {
  width:48%;
  margin-right:4%;
  border-radius: 5px;
}

#price_table_2 {
  width:48%;
}

#price_table_1 table {
  width:100%;
  border-spacing: 0px;
  color: #777777;
  border:1px solid #77777754;
  border-radius:8px;
}

#price_table_1 th, #price_table_1 thead > tr, #price_table_1 thead {
  background:  #007CA4;
  color: #fff;
  padding-top:20px;
  padding-bottom:20px;
  padding-left:10px;
  padding-right:10px;
}


#price_table_1 td {
  padding:7px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
}

#price_table_2 table {
  width:100%;
  border-spacing: 0px;
  color: #777777;
  border:1px solid#77777754;
  border-radius:8px;
}

#price_table_2 th, #price_table_2 thead > tr, #price_table_2 thead {
  /*background:  #007CA4;*/
  color: #007CA4;
  padding-top:20px;
  padding-bottom:20px;
  padding-left:10px;
  padding-right:10px;
}

#price_table_2 td {
  padding:7px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
}

table {
  font-family: 'Roboto', sans-serif;
  font-style:normal;
}

.th_first {
  font-weight:600; 
}

.th_second {
  font-size:13px;
  font-weight:400;
}

#table_map_kobala {
  width:359px;
  height:324px;
}

#container_map_kobala {
  width:99%;
}

#map_kobala {
  width:100%;
  height:100%;
}



</style>