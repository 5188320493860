<template>
  <!--<div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/>-->
  <div id ="wrapper">
    <Header></Header>
      <div id="content">
        <!--<transition name="fade-out"  mode="out-in">
          <router-view></router-view>
        </transition>-->
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
          <!--<router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>-->
      </div>
    
  </div>
</template>

<script>

import Header from '@/views/Header.vue'

export default {

  components: {
    Header
  },

  data: () => ({

  }),

  methods: {
    /*fetchData() {
        this.$store.dispatch('FETCH_DATA')
        .then(response => {
          window.console.log("### App.vue@fetchData success ###")
          window.console.log(response.data.data)
        })
        .catch(error => {
          window.console.error("### App.vue@fetchData error ###");
          window.console.error(error)
        })
        .finally(() =>{
          //window.console.log("fetching data done :)")
        })
    }*/

    fetchDataFromJson() {
      this.$store.dispatch('FETCH_DATA_FROM_JSON')
      .then(response => {
        window.console.log(response)
      })
      .catch(err => {
        window.console.error(err)
      })
      
    }
  },

  created() {
    this.$store.dispatch('SET_LANGUAGE', 'sl')
    //this.fetchData()
    this.fetchDataFromJson();
  },

  mounted() {
    //window.console.log(this.$store.getters.language)
  },

  unmounted() {

  }
}

</script>

<style>


/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/

  
  .pointer {cursor: pointer;}
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
</style>
