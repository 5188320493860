import { aggregateCartItems } from '@/helpers/utilities.js'

export default {
    language(state) {
        return state.language
    },

    languageData(state) {
        return state.languageData
    },
    
    cartItems(state) {
        return state.cartItems
    },

    cartItemsAggregated(state) {
        return aggregateCartItems(state.cartItems)
    },

    cartItemById: (state) => (id) => {
        return aggregateCartItems(state.cartItems).find(item => item.id == id)
    },

    price(state) {
        if(state.cartItems.length > 0) {
            let sum = 0.00;
            state.cartItems.forEach(item => {
                sum = sum + item.price
            })

            return sum.toFixed(2);
        }

        return 0.00;
    },

    selectedCategories(state) {
        return state.selectedCategories
    },

    dates(state) {
        return state.dates
    } ,

    categories(state) {
        return state.categories
    },

    selectedDates(state) {
        return state.selectedDates
    }
}