import data from '@/assets/data.json'

export default {
    SET_LANGUAGE (state, value) {
        state.language = value
        if(state.language == 'sl') {
            state.languageData = data.language.sl
        }

        if(state.language == 'it') {
            state.languageData = data.language.it
        }

        if(state.language == 'de') {
            state.languageData = data.language.de
        }

        if(state.language == 'en') {    
            state.languageData = data.language.en
        }
    },

    ADD_TO_CART(state, item){
        state.cartItems.push(item)
    },

    EMPTY_CART(state, item) {
        state.cartItems = []
    },

    REMOVE_FROM_CART(state, itemId){
        for(var i = 0; i<state.cartItems.length; i++) {
            if(itemId === state.cartItems[i].id) {
                state.cartItems.splice(i, 1);
                break;
            }
        }
    },

    CLEAR_FROM_CART(state, itemId){
        for(var i = 0; i<state.cartItems.length; i++) {
            if(itemId === state.cartItems[i].id) {
                state.cartItems.splice(i, 1);
                i--;
                //break;
            }
        }
    },

    ADD_CATEGORY(state, category) {
        state.selectedCategories.push(category)
    },

    REMOVE_CATEGORY(state, category) {
        for(var i=0; i<state.selectedCategories.length; i++) {
            if(category == state.selectedCategories[i]) {
                state.selectedCategories.splice(i,1);
                break;
            }
        }
    },

    SAVE_DATA(state, data) {
        state.categories = [];
        state.dates = [];

        //napolni datume
        for(var i=0; i<data.dates.length; i++) {
            state.dates.push(data.dates[i]);
        }

        //napolni kategorije s podatki
        for(var j=0; j<data.categories.length; j++) {
            state.categories.push(data.categories[j])
        }
    },

    ADD_DATE(state, date) {
        state.selectedDates.push(date)
    },

    REMOVE_DATE(state, date){
        for(var i = 0; i<state.selectedDates.length; i++) {
            if(date.id === state.selectedDates[i].id) {
                state.selectedDates.splice(i, 1);
                break;
            }
        }
    },

    TRUNCATE_SELECTED_DATES(state) {
        state.selectedDates = []

    }


}