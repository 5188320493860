<template>
    <div id="ln_container">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a href="javascript;" class="nav-link" @click.prevent="setActive('sl')" :class="{ active: isActive('sl'), 'not-active': !isActive('sl') }"> {{ slText }}</a>
            </li>
            <!--<li class="nav-item">
                <a href="javascript;" class="nav-link" @click.prevent="setActive('it')" :class="{ active: isActive('it'), 'not-active': !isActive('it') }">{{ itText }}</a>
            </li>
            <li class="nav-item">
                <a href="javascript;" class="nav-link" @click.prevent="setActive('de')" :class="{ active: isActive('de'), 'not-active': !isActive('de') }">{{ deText }}</a>
            </li>-->
            <li class="nav-item">
                <a href="javascript;" class="nav-link" @click.prevent="setActive('en')" :class="{ active: isActive('en'), 'not-active': !isActive('en') }">{{ enText }}</a>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'language-selector',
    data: () => ({
        slText: "Slovensko",
        itText: "Italiano",
        deText: "Deutsch",
        enText: "English",
        activeItem: null,
    }),

    watch: {

    },

    computed: {
        
    },

    methods: {

        isActive(menuItem) {
            if(menuItem === 'sl') {
                return this.activeItem === this.slText
            }

            if(menuItem === 'it') {
                return this.activeItem === this.itText
            }

            if(menuItem === 'de') {
                return this.activeItem === this.deText
            }

            if(menuItem === 'en') {
                return this.activeItem === this.enText
            }
        },
        
        setActive(menuItem) {
            if(menuItem === 'sl') {
                this.activeItem = this.slText
                this.$store.dispatch('SET_LANGUAGE', 'sl');
                //console.log(this.$store.getters.language)
            }

            if(menuItem === 'it') {
                this.activeItem = this.itText
                this.$store.dispatch('SET_LANGUAGE', 'it');
                //console.log(this.$store.getters.language)
            }

            if(menuItem === 'de') {
                this.activeItem = this.deText
                this.$store.dispatch('SET_LANGUAGE', 'de');
                //console.log(this.$store.getters.language)
            }

            if(menuItem === 'en') {
                this.activeItem = this.enText
                this.$store.dispatch('SET_LANGUAGE', 'en');
                //console.log(this.$store.getters.language)
            }

        }
    },

    created() {
        this.activeItem = this.slText;
    },

    mounted() {

    },

    unmounted() {

    }
}

</script>

<style scoped>
    .nav-link {
        text-transform: uppercase;
        display: block;
        padding: .5rem 1rem;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        text-decoration:none;
        color: #000;
        font-weight:600;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: #007CA4;
        background-color: #007CA4;
        border-color: #007CA4;
    }

    .nav-tabs {
        /*border-bottom: 1px solid #dee2e6;*/
        margin-right:5px;
    }

    .nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav-justified .nav-item {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-align: center;
    }

    .nav-tabs .nav-item {
        /*margin-bottom: -1px;*/
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color:  #007CA4;
        background-color: #fff;
        border-color:  #007CA4  #007CA4 #fff;
    }

    .not-active {
        border-bottom: 1px solid #007CA4 !important;
    }

    .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }

    ul {
        margin-top: 0;
    }

    a:hover {
        cursor:default;
    }
</style>