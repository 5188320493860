import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: ''
  },

  {
    path: '/store',
    name: 'Store',
    component: () => import('@/views/Store.vue')
  },

  {
    path: '/dates',
    name: 'SelectDate',
    component: () => import('@/views/SelectDate.vue')
  },

  {
    path: '/informations',
    name: 'Informations',
    component: () => import('@/views/Informations.vue')
  },

  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/Help.vue')
  },

  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/Cart.vue')
  },

  {
    path: '/cart/:id',
    name: 'CartItem',
    component: () => import('@/views/CartItem.vue')
  },

  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/Payment.vue')
  },

  {
    path: '/conclusion',
    name: 'Conclusion',
    component: () => import('@/views/Conclusion.vue')
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
