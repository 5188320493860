export default {
    language: 'sl',
    languageData: {},
    cartItems: [],
    dates:[],
    categories: [],
    items:[],

    selectedCategories: [],
    selectedDates: []
}