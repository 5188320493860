import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import VWave from 'v-wave'
import mitt from 'mitt';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-touch-ripple/dist/vue-touch-ripple.css'

export const emitter = mitt();
const app = createApp(App);
app.provide('moment', moment);

app.config.productionTip = false;

createApp(App)
.use(store)
.use(router)
.use(VWave)
.mount('#app')
