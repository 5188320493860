import { post, get, destroy, API_ENDPOINT } from '.././helpers/api.js'
import data from '@/assets/data.json'

export default {
    SET_LANGUAGE(context, language) {
        context.commit('SET_LANGUAGE', language)
    },

    ADD_TO_CART(context, item) {
        context.commit('ADD_TO_CART', item)
    },

    REMOVE_FROM_CART(context, itemId) {
        context.commit('REMOVE_FROM_CART', itemId)
    },

    CLEAR_FROM_CART(context, itemId) {
        context.commit('CLEAR_FROM_CART', itemId)
    },

    EMPTY_CART(context) {
        context.commit('EMPTY_CART')
    },

    ADD_CATEGORY(context, category) {
        context.commit('ADD_CATEGORY', category)
    },

    REMOVE_CATEGORY(context, category) {
        context.commit('REMOVE_CATEGORY', category)
    },

    ADD_DATE(context, date) {
        context.commit('ADD_DATE', date)
    },

    REMOVE_DATE(context, date) {
        context.commit('REMOVE_DATE', date)
    },

    TRUNCATE_SELECTED_DATES(context) {
        context.commit('TRUNCATE_SELECTED_DATES')
    },

    SAVE_DATA(context, data) {
        context.commit('SAVE_DATA', data)
    },

    //nalozi podatke
    FETCH_DATA(context) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1')
            .then(response => {
                context.dispatch('SAVE_DATA', response.data.data)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    FETCH_DATA_FROM_JSON(context) {
        context.dispatch('SAVE_DATA', data.response.data)
        return data.response.data
    },

    //plačilo
    PAYMENT(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/payment', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    }
}