<template>
    <div id="header">
        <div id="logo_container">
            <img id="logo" :src="getLogo" alt="Turistično društvo gorje" @click="goTo()">
        </div>

        <div id="time_container">
            <span class="datetime" id="date">{{ date }}</span>&nbsp;
            <span class="datetime" id="time">{{ time }}</span>
        </div>

        <div id="language_selector_container">
            <div id="ln_first_item"></div>
            <div id="ln_second_item">
                 <language-selector></language-selector>
            </div>
        </div>
    </div>
</template>

<script>

//import logo from '@/assets/vintgar_logo.png'
import logo from '@/assets/logo.jpg'
import moment from 'moment'
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  components: { LanguageSelector },
    name: 'Header',
    LanguageSelectorprops: {
        msg: String
    },
    data: () => ({
        timeInterval: null,
        dateInterval: null,
        date: null,
        time: null
    }),

    watch: {
        time(newValue, oldValue) {
            //window.console.log("Watch Time: " + this.time);
        }
    },

    computed: {
        getLogo() {
            return logo;
        }
    },

    methods: {
        goTo() {
            return this.$router.push({
                name: 'Home'
            })
        }
    },

    created() {
        this.date = moment().format("DD.MM.YYYY");
        this.time = moment().format("HH:mm:ss")

        //window.console.log("Date: " + this.date)
        //window.console.log("Time: " + this.time)

        this.dateInterval = setInterval(() => {
            this.date = moment().format("DD.MM.YYYY");
            //window.console.log("Date Iterval: " + this.date);
        }, 60000)

        this.timeInterval = setInterval(() => {
            this.time = moment().format("HH:mm:ss");
        }, 1000)


    },

    mounted() {
        //window.console.log("Header mounted...")
    },

    unmounted() {
        clearInterval(this.timeInterval)
    }
}

</script>

<style scoped>
    #logo_container {
        position: absolute;
        width: 224px;
        height: 64px;
        left: 7px;
        /*top: 12px;*/
        top: 5px;
        /*border:1px solid black;*/
    }

    #time_container {
        position: absolute;
        width: 215px;
        height: 24px;
        left: 533px;
        top: 5px;

        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: right;

        color: #000000;
    }

    #logo {
        /*width:60%;*/
        width:66%;
        height:auto;
    }

    .datetime {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size:18px;
    }

    #language_selector_container {
        width:100%;
        min-height:30px;
        /*border:1px solid #007CA4;*/
        position:absolute;
        top:70px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    #ln_first_item {
        /*width:35%;*/
        width:60%;
        border-bottom:1px solid #007CA4;
    }

    #ln_second_item {
        /*width:65%;*/
        width:40%;
    }
</style>